import Vue from "vue";
import Component from "vue-class-component";
import * as BidEnums from "@/enums/bid";
import { Mixins } from "vue-property-decorator";
import { HasEnumMethods } from "../has-enum-methods";

@Component
export class HasBidOptions extends Mixins(HasEnumMethods) {
  BidTypeOptions = this.optionsFromEnum(BidEnums.BidType, "bid.enums.types");
  BidSuspensiveConditionWeekOptions = this.optionsFromEnum(BidEnums.SuspensiveConditionWeek, "bid.enums.suspensive_condition_weeks");
}
