import Vue from "vue";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { HasBidOptions } from "@/mixins/bids/has-options";
import { HasNotaryOptions } from "@/mixins/has-notary-options";
import { HasActivity } from "../activities/has-activity";
import { BidStatus, BidType, SuspensiveConditionWeek } from "@/enums/bid";
import { RepresentativeMaritalState } from "@/enums/representative";
import { cloneDeep } from "lodash";
import { DocumentFolder } from "@/store/modules/document.store";

export declare interface BidInfoBlock {
  id: string;
  icon: string;
  title: string;
  description: string;
  values: BidInfoBlockValue[];
}

export declare interface BidInfoBlockValue {
  /**
   * The required key that is also present on the payload or meta payload
   */
  key: string;
  group?: string;
  label?: string;
  allowed?: boolean;
  type?: "label" | "subtypes" | "number" | "file" | "yes-no" | "money" | "select" | "multiselect" | "textinput" | "textarea" | "wysiwyg" | "date" | "representatives";
  cols?: 1 | 2;
  hidden?: boolean;
  props?: {
    /**
     * The input name used in validations
     */
    name?: string;

    /**
     * Used on subtypes to filter the subtypes by type
     */
    type?: string | null;

    /**
     * Determine whether the input should be required or not
     */
    required?: boolean;

    /**
     * The validation rules
     */
    rules?: string;

    /**
     * The placeholder of the input
     */
    folder?: DocumentFolder;

    /**
     * Whether the input should be disabled or not
     */
    disabled?: boolean;

    /**
     * The suffix of the input
     *
     * @example m | m² | %
     */
    suffix?: string;

    /**
     * The options of the input
     */
    options?: Array<{ label: string; value: string | number }>;

    /**
     * Wheter we can create new items in the select or multiselect
     * (Only for select and multiselect)
     */
    taggable?: boolean;

    /**
     * Wheter the input should be clearable or not
     * (Only for select and multiselect)
     */
    clearable?: boolean;

    /**
     * The height of the field
     * (Only for textarea)
     */
    height?: string;

    /**
     * The secondary label aligned to the right
     */
    subLabel?: string;
  };
  callback?: (value: any) => void;
}

@Component
export class HasBidConfig extends Mixins(HasBidOptions, HasNotaryOptions, HasActivity) {
  @Action("property/read") readProperty!: (payload: { activity_id: number }) => Promise<Property>;

  @Action("bid/read") readBid!: (payload: { id: number }) => Promise<Bid>;

  property: Property | null = null;

  bid: Bid | null = null;

  configLoading = false;

  verifyBeforeApproval = this.$route.name === "dossier-bid-update-approve";

  showDemoFunctionality = process.env.VUE_APP_DEMO_FUNCIONALITY === "true";

  payload: any = {
    activity_id: "",
    status: BidStatus.Candidate,
    amount: null,
    type: null,
    company_details: null,
    electricity_inspection_conform_accepted: null,
    struck_by_red_line_accepted: null,
    habitable_after_deed_period_accepted: null,
    fuel_oil_tank_compliant_accepted: null,
    asbestos_present_accepted: null,
    date: null,
    epc_accepted: null,
    notes_accepted: null,
    visible: false,
    signing_details: {
      expiration_date: "",
      notary_info: "",
      notary_city: "",
      remarks: "",
      counter_signed_at: null,
    },
    representatives: [],
    files: [],
    suspensive_conditions_yes_no: null,
    suspensive_conditions: "",
    suspensive_conditions_weeks: null,
    rejection_reason: null,
  };

  async mounted() {
    this.configLoading = true;

    await this.loadActivity();

    await this.loadBid();

    this.getNotaryOptions();

    this.property = await this.readProperty({ activity_id: this.activity.id });

    await this.setInitialPayload();

    this.configLoading = false;

    this.afterMounted();
  }

  get bidTabs() {
    return [this.bidInfo, this.representatives].filter(Boolean) as BidInfoBlock[];
  }

  get bidInfo(): BidInfoBlock {
    let values: BidInfoBlockValue[] = [
      { group: "type", type: "label", key: "labels.bid" },
      { group: "bid", type: "money", key: "amount", props: { required: true, rules: "required" } },
      {
        group: "bid",
        type: "select",
        key: "type",
        props: { required: true, options: this.BidTypeOptions, rules: "required" },
        callback: (value) => {
          if (value !== BidType.Company) {
            this.payload.company_details = null;
          } else {
            this.payload.company_details = {
              name: "",
              vat_number: "",
              corporate_headquarters: "",
            };
          }
        },
      },
      {
        group: "bid",
        type: "yes-no",
        key: "suspensive_conditions_yes_no",
        props: { required: true, rules: "required" },
        callback: (value) => (!value ? (this.payload.suspensive_conditions_weeks = this.payload.suspensive_conditions = null) : ""),
      },
      {
        group: "bid",
        type: "select",
        key: "suspensive_conditions_weeks",
        hidden: !this.payload.suspensive_conditions_yes_no,
        props: { required: true, options: this.BidSuspensiveConditionWeekOptions, rules: "required" },
      },
      { group: "bid", type: "textinput", key: "signing_details.remarks" },
      { group: "bid", type: "date", key: "date", props: { required: true, rules: "required" } },
      { group: "bid", type: "date", key: "signing_details.expiration_date", props: { required: true, rules: "required" } },
      {
        group: "bid",
        type: "yes-no",
        key: "signing_details.unknown_notary",
        callback: (value) => (value ? (this.payload.signing_details.notary_info = this.payload.signing_details.notary_city = null) : ""),
      },
      {
        group: "bid",
        type: "select",
        key: "signing_details.notary_info",
        props: {
          disabled: this.payload.signing_details.unknown_notary,
          required: this.verifyBeforeApproval && !this.payload.signing_details.unknown_notary,
          options: this.officeNotaryOptions,
          rules: this.verifyBeforeApproval && !this.payload.signing_details.unknown_notary ? "required" : "",
          taggable: true,
        },
      },
      { group: "bid", type: "textinput", key: "signing_details.notary_city", props: { disabled: this.payload.signing_details.unknown_notary } },
      { group: "bid", type: "file", key: "files", props: { required: true, folder: DocumentFolder.openSalesAgreement } },
      { group: "bid", type: "file", key: "files_counter_signed", props: { folder: DocumentFolder.approvedSalesAgreement } },
      { group: "bid", type: "date", key: "signing_details.counter_signed_at", props: { required: this.verifyBeforeApproval } },
    ];
    // :create-option="(o) => ({ label: o, value: o })"
    return {
      id: "bid",
      icon: "currency-euro",
      title: "Bod",
      description: "Details van het bod.",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  get representatives(): BidInfoBlock {
    let values: BidInfoBlockValue[] = [
      { group: "company_details", type: "label", key: "labels.company_details", hidden: this.payload.type !== BidType.Company },
      {
        group: "company_details",
        type: "textinput",
        key: "company_details.name",
        hidden: this.payload.type !== BidType.Company,
        props: { required: this.verifyBeforeApproval, rules: this.verifyBeforeApproval ? "required" : "" },
      },
      {
        group: "company_details",
        type: "textinput",
        key: "company_details.vat_number",
        hidden: this.payload.type !== BidType.Company,
        props: { required: this.verifyBeforeApproval, rules: this.verifyBeforeApproval ? "required" : "" },
      },
      {
        group: "company_details",
        type: "textinput",
        key: "company_details.corporate_headquarters",
        hidden: this.payload.type !== BidType.Company,
        props: { required: this.verifyBeforeApproval, rules: this.verifyBeforeApproval ? "required" : "" },
      },

      { group: "type", type: "representatives", key: "representatives", props: { required: this.verifyBeforeApproval } },
    ];

    return {
      id: "building",
      icon: "home",
      title: "Partij(en)",
      description: "Gegevens van kandidaat koper(s).",
      values: this.mapValuesForInfoBlock(values),
    };
  }

  /**
   * @name After mounted hook
   *
   * @description This is a hook that can be used in the child component
   */
  afterMounted() {}

  async loadBid() {
    if (!this.$route.params.bidId) {
      return;
    }

    this.bid = await this.readBid({ id: Number(this.$route.params.bidId) });
  }

  async setInitialPayload() {
    return new Promise((resolve) => {
      if (this.bid) {
        this.payload = cloneDeep(this.bid);

        if (this.payload.type === BidType.Company && !this.payload.company_details) {
          this.payload.company_details = {
            name: "",
            vat_number: "",
            corporate_headquarters: "",
          };
        }

        this.payload.files_counter_signed = this.payload.files.filter((f: any) => f.folder === DocumentFolder.approvedSalesAgreement) ?? [];
        this.payload.files = this.payload.files.filter((f: any) => f.folder === DocumentFolder.openSalesAgreement) ?? [];

        return resolve(true);
      }

      this.payload.activity_id = this.activity.id;

      if (this.activity.property_sale && this.activity.property_sale.offer_from) {
        this.payload.amount = this.activity.property_sale.offer_from;
      }

      return resolve(true);
    });
  }

  setDevPayload() {
    this.payload.company_details = null;
    this.payload.electricity_inspection_conform_accepted = null;
    this.payload.struck_by_red_line_accepted = null;
    this.payload.habitable_after_deed_period_accepted = null;
    this.payload.fuel_oil_tank_compliant_accepted = null;
    this.payload.asbestos_present_accepted = null;
    this.payload.epc_accepted = null;
    this.payload.notes_accepted = null;
    this.payload.visible = false;
    this.payload.type = BidType.Private;
    this.payload.date = "2024-05-08T21:59:59.000000Z";
    this.payload.signing_details = {
      expiration_date: "2024-05-08T21:59:59.000000Z",
      notary_info: "Act & Lex",
      notary_city: "Neerpelt",
      remarks: "Dit is een test",
      counter_signed_at: "",
    };
    this.payload.representatives = [
      {
        title: "mr",
        first_name: "Demo",
        last_name: "Bieder",
        national_register_number: "12.31.23-123-12",
        birth_date: "1990-01-01",
        birth_place: "Peer",
        birth_country: "BE",
        marital_state: RepresentativeMaritalState.Unmarried,
        street: "Kerkstraat",
        additional: "",
        country: "BE",
        city: "Peer",
        zip: "3900",
        number: "1",
        phone: "0473 84 99 37",
        email: "fr+bod@salamander.be",
      },
    ];
    this.payload.files = [];
    this.payload.files_counter_signed = [];
    this.payload.suspensive_conditions_yes_no = true;
    this.payload.suspensive_conditions_weeks = SuspensiveConditionWeek.Four;
    this.payload.suspensive_conditions = "Dit zijn wat opschortende voorwaarden.";
  }

  /**
   * @name Map values for info block
   *
   * @description
   * Map the values to the info block
   * - Will set a translated label based on the key, if no label is provided
   * - Will determine wheter the property is allowed based on the type
   * - Will set a default name if none is provided
   *
   * @param {BidInfoBlockValue[]} values
   *
   * @returns {BidInfoBlockValue[]}
   */
  mapValuesForInfoBlock(values: BidInfoBlockValue[]): BidInfoBlockValue[] {
    return values.map((v: BidInfoBlockValue) => ({
      ...v,
      label: v?.label ? v.label : (this.$t(`bid.form.titles.${v.key}`) as string),
      allowed: true,
      props: { ...(v.props ?? {}), name: v.props?.name ? v.props.name : v.key.replace(".", "_") },
    }));
  }
}
