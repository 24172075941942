
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { HasBidConfig } from "@/mixins/bids/has-config";
  import { Component, Mixins, Ref } from "vue-property-decorator";
  import { formatDate } from "@/utils/formatters";
  import { Action } from "vuex-class";
  import { HasObjectMethods } from "@/mixins/has-object-methods";
  import { BidStatus } from "@/enums/bid";

  @Component({
    components: {
      PropertyPartGeneralHeader: () => import("@/views/auth/properties/parts/general/header.vue"),
      PropertyPartGeneralInfoBlock: () => import("@/views/auth/properties/parts/general/info-block.vue"),

      // General
      BidPartDesktopLabel: () => import("@/views/auth/properties/parts/desktop/label.vue"),
      BidPartDesktopNumber: () => import("@/views/auth/properties/parts/desktop/number.vue"),
      BidPartDesktopTextinput: () => import("@/views/auth/properties/parts/desktop/textinput.vue"),
      BidPartDesktopTextarea: () => import("@/views/auth/properties/parts/desktop/textarea.vue"),
      BidPartDesktopWysiwyg: () => import("@/views/auth/properties/parts/desktop/wysiwyg.vue"),
      BidPartDesktopYesNo: () => import("@/views/auth/properties/parts/desktop/yes-no.vue"),
      BidPartDesktopMoney: () => import("@/views/auth/properties/parts/desktop/money.vue"),
      BidPartDesktopSelect: () => import("@/views/auth/properties/parts/desktop/select.vue"),
      BidPartDesktopMultiselect: () => import("@/views/auth/properties/parts/desktop/multiselect.vue"),
      BidPartDesktopDate: () => import("@/views/auth/properties/parts/desktop/date.vue"),
      BidPartDesktopRepresentatives: () => import("@/views/auth/bids/parts/desktop/representatives.vue"),
      BidPartDesktopFile: () => import("@/views/auth/bids/parts/desktop/file.vue"),

      FormSubmit: () => import("@/components/forms/submit.vue"),
      FormBase: () => import("@/components/forms/base.vue"),

      PreviewMediaModal: () => import("@/components/modals/preview-media.vue"),
    },
  })
  export default class PageBidCreate extends Mixins(HasBidConfig, HasObjectMethods, HasFormErrors) {
    @Action("bid/create") createBid!: (payload: any) => Promise<Bid>;

    @Action("bid/patch") updateBid!: (payload: any) => Promise<Bid>;

    @Ref() form!: FormClass;

    /**
     * @name Last changed date
     *
     * @description Get a readable format of when the property was last changed.
     */
    get lastChangeDate() {
      return "";
    }

    /**
     * @name Determine if the fields are disabled or not
     *
     * @description Editing the fields is only possible if the bid is not yet approved. (this does not apply to emails)
     */
    get isDisabled() {
      // Create flow
      if (!this.bid) {
        return true;
      }

      // Update flow
      return this.bid.status === BidStatus.Candidate;
    }

    /**
     * @name Update bid properties
     *
     * @description  Update the payload  based on the path provided. When a . is detected in the path go a level deeper in the object.
     */
    updateBidProperties(path: any, value: any, callback?: (v: any) => void) {
      if (!this.payload) {
        return;
      }

      path.split(".").reduce((acc: any, key: string, index: number, arr: any) => {
        if (index === arr.length - 1) {
          this.$set(acc, key, value);
        }

        return acc[key];
      }, this.payload);

      if (callback) {
        callback(value);
      }
    }

    async submit() {
      if (!this.payload) {
        return;
      }

      try {
        if (["dossier-bid-update", "dossier-bid-update-approve"].includes(`${this.$route.name}`)) {
          await this.handleBidUpdate(Number(this.$route.params.bidId));
        } else {
          await this.handleBidCreate();
        }
      } catch (e) {
        this.errorResponse = this.formatErrors(e);

        if (this.errorResponse && this.errorResponse.errors && this.errorResponse.errors.length > 0) {
          this.errorResponse.errors.forEach((error: any) => {
            if (error.localizedMessage) {
              this.$toast.open({ message: error.localizedMessage, type: "error" });
            } else {
              this.$toast.open({ message: error.message, type: "error" });
            }
          });
        } else {
          this.$toast.open({ message: "Oops er ging iets mis", type: "error" });
        }
      }

      this.form.reset();
    }

    async handleBidCreate() {
      let payload = { ...this.payload };

      payload.files = [
        ...(this.payload.files ?? []).filter((f: any) => !f.hasOwnProperty("original_url")),
        ...(this.payload.files_counter_signed ?? []).filter((f: any) => !f.hasOwnProperty("original_url")),
      ];

      await this.createBid(payload);

      // @ts-ignore
      this.$router.push({ name: "dossier-detail", params: { id: this.activity.id }, query: { tab: "biedingen" } });

      this.$toast.open({ message: "Bod geplaatst!", type: "success" });
    }

    async handleBidUpdate(bidId: number) {
      let payload = { ...this.payload };

      payload.files = [
        ...(this.payload.files ?? []).filter((f: any) => !f.hasOwnProperty("original_url")),
        ...(this.payload.files_counter_signed ?? []).filter((f: any) => !f.hasOwnProperty("original_url")),
      ];

      // If we are updating an already approved bid, send only the changed email address.
      if (this.bid!.status === BidStatus.Approved) {
        payload = {
          representatives: payload.representatives,
        };
      }

      await this.updateBid({ id: bidId, ...payload });

      this.$toast.open({ message: "Bod aangepast!", type: "success" });
    }

    handleInvalidSubmit() {
      this.$toast.open({ message: `${this.form.allErrors!.length} veld${this.form.allErrors!.length > 1 ? "en zijn" : " is"}  onjuist ingevuld` as string, type: "error" });
    }
  }
